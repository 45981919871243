<template>
    <v-card tile>
        <v-card-title class="headline main white--text"> Создание проекта </v-card-title>

        <v-card-text>
            <v-form v-model="valid" class="mt-6" ref="form" @submit.prevent="createProject">
                <v-text-field
                    color="main"
                    prepend-inner-icon="mdi-folder"
                    filled
                    :rules="rules"
                    v-model="projectName"
                    label="Введите название проекта"
                    :required="isOnBoardingActive ? false : true"
                ></v-text-field>
            </v-form>
        </v-card-text>

        <v-card-actions>
            <v-btn
                color="main"
                class="ml-3 mb-5 white--text"
                @click="createProjectWithOnboarding"
                :disabled="!valid"
            >
                Создать
            </v-btn>
            <v-btn outlined class="ml-4 mb-5" @click="cancel"> Отмена </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { getUser } from '@root/src/api/auth'
import { ref } from 'vue'
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'CreateProjectDialog',
    props: {},
    components: {},
    data: () => ({
        projectName: null,
        valid: false,
        max: 40,
        isOnBoardingActive: ref(true),
        isAdmin: ref(false),
    }),
    computed: {
        rules() {
            const r1 = (v) => (v || '').length <= this.max || `Максимальная длина: 40 символов`
            const r2 = (v) => !!v || 'Введите название'
            return [r1, r2]
        },
    },

    methods: {
        async createProjectWithoutOnboarding() {
            this.isOnBoardingActive = false
            this.createProject()
        },
        async createProjectWithOnboarding() {
            this.isOnBoardingActive = true
            this.createProject()
        },
        async createProject() {
            const projectName = this.projectName
            const project = await this.$store.dispatch('project/createProject', {
                projectName: projectName,
                isOnBoardingActive: this.isOnBoardingActive,
                billingEnabledStatus: this.isOnBoardingActive,
            })
            this.cancel()

            if (this.isOnBoardingActive && this.isAdmin) this.$router.push(`project/${project.id}/onBoarding`)
            else this.$router.push(`project/${project.id}`)
        },
        cancel() {
            this.$refs.form.reset()
            this.$refs.form.resetValidation()

            this.$store.commit('project/updateIsCreateProjectDialog', false)
        },
    },

    async created() {
        const user = await getUser()
        this.isAdmin = user.data.isAdmin
        console.log(this.isAdmin)
    },
    mounted() {},
}
</script>

<style lang="sass" scoped>
button
    border-color: #0000001F
</style>
